import React from "react";
import "./CouponModal.css";

const CouponModal = ({ isOpen, emoji, name, description, status, metadata, onClose }) => {

  if (!isOpen) return null;

  return (
    <div className="coupon-modal-backdrop" onClick={onClose}>
      <div className="coupon-modal">
        <div className="coupon-modal-inner">
        <h1>{emoji}</h1>
        <h3>{name}</h3>
        <p>{description}</p>
        <p className="coupon-modal-status">{status}</p>
        <p className="coupon-modal-metadata">{metadata}</p>
      </div>
      </div>
      <button className="coupon-modal-button" onClick={onClose}>❌</button>
    </div>
  );
};

export default CouponModal;
