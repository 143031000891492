import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useGesture } from "react-use-gesture";
import "./Coupon.css";

const Coupon = ({ emoji, name, active, description, onClick }) => {
  const [dragging, setDragging] = useState(false);
  const [rotation, setRotation] = useState(0);

  const originX = Math.random() * (window.innerWidth - 200) + 50;
  const originY =  Math.random() * (window.innerHeight - 200) + 50;

  const [{ x, y }, api] = useSpring(() => ({
    x: originX,
    y: originY
    // config: { mass: 1, tension: 300, friction: 40 },
  }));

  const bind = useGesture({
    onDrag: ({ tap, down, movement: [mx, my], offset: [ox, oy] }) => {
      if (tap) {
        onClick();
      }
      // api.start({ x: down ? ox : originX, y: down ? oy : originY, immediate: down });
    }
  });

  useEffect(() => {
    const randomRotation = Math.floor(Math.random() * 41) - 20;
    setRotation(randomRotation);
  }, []);

  return (
    <animated.div
      className="coupon"
      style={{
        x,
        y,
        fontSize: "50px",
        touchAction: "none",
        transform: `rotate(${rotation}deg)`,
        opacity: active ? 1 : 0.6
      }}
      {...bind()}
    >
      <div className="coupon-inner">
      <span role="img" aria-label={name}>
        {emoji}
      </span>
      </div>
    </animated.div>
  );
};

export default Coupon;
