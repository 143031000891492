import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Coupon from "./Coupon";
import CouponModal from "./CouponModal";
import "./Main.css";

const Main = () => {
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const coupons = [
    { emoji: "🧗‍♂️", name: "Belay Buddy", description: "Climb rocks with a trained belay buddy.", 
      status: "Claimed", active: false, metadata: "📆 Birthday 2023" },

    { emoji: "🧑‍🍳", name: "Fine Dining at Home", description: "Enjoy a home-cooked, 3-course meal featuring the cuisine of your choice.", 
      status: "Claimed", active: false, metadata: "📆 Birthday 2023" },

    { emoji: "🐶", name: "Animal Meme", description: "Receive a mood boosting animal meme.", 
      status: "♾️ Remaining", active: false, metadata: "📆 Birthday 2023" },

    { emoji: "🛀", name: "Banya Day", description: "Relax your body in the sauna and hot baths.", 
      status: "Claim Apr 20", active: true, metadata: "📆 Birthday 2024" },

    { emoji: "💃", name: "Fashion Show", description: "Shop to replenish your retired clothes and put on an all-day fashion show.", 
      status: "1 Remaining", active: true, metadata: "📆 Birthday 2024" },

    { emoji: "🌵", name: "Plant Pet", description: "Pick out a big plant and bring it home.", 
      status: "1 Remaining", active: true, metadata: "📆 Birthday 2024" },
  ];

  const openModal = (coupon) => {
    setSelectedCoupon(coupon);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCoupon(null);
    setIsModalOpen(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="header">Lindsay's Coupons</div>
      <div className="main">
        {coupons.map((coupon, index) => (
          <Coupon
            key={coupon.emoji}
            emoji={coupon.emoji}
            name={coupon.name}
            active={coupon.active}
            description={coupon.description}
            onClick={() => openModal(coupon)}
          />
        ))}
        <CouponModal
          isOpen={isModalOpen}
          emoji={selectedCoupon?.emoji}
          name={selectedCoupon?.name}
          description={selectedCoupon?.description}
          status={ selectedCoupon?.status}
          metadata={ selectedCoupon?.metadata}
          onClose={closeModal}
        />
      </div>
      <div className="footer">With ❤️ from Sam</div>
    </DndProvider>
  );
};

export default Main;

